import Api from '@/services/Api'

export default {

    // Get all the possible types of Assessments.
    getAllAssessmentTypes() {
        return Api().get('getAllAssessmentTypes')
    },

    // Get all the possible types of Payments.
    getAllPaymentTypes() {
        return Api().get('getAllPaymentTypes')
    },
    
    // Get all the Assessments a person owes.
    getAssessmentsByPersonID(payload) {
        return Api().get(`getAssessmentsByPersonID/${payload.person_id}`)
    },

    // Get all the Payments a person has made.
    getPaymentsByPersonID(payload) {
        return Api().get(`getPaymentsByPersonID/${payload.person_id}`)
    },
    
    // Remove an Assessment
    deleteAssessment(payload) {
        return Api().post('deleteAssessment', payload)
    },

    // Remove a Payment
    deletePayment(payload) {
        return Api().post('deletePayment', payload)
    },
}