<template>
    <v-container v-if="$store.state.user">
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <div v-if="person">
                    <h1 v-if="!person.archived" class="display-2 font-weight-bold mb-3">
                        {{person.first_name}} {{person.last_name}}
                    </h1>
                    <h1 v-if="person.archived" class="display-2 font-weight-bold mb-3">
                        {{person.first_name}} {{person.last_name}} <p class="display-1">[Archived]</p>
                    </h1>
                </div>
            </v-col> 
        </v-row>

        <v-row>
            <v-col>
                <div v-if="person && association" class="text-center">
                    Association: {{association.name}} <br>
                    Association Interest Rate: {{association.interest}}% <br>
                    File Number: {{person.file_number}}
                        <p v-if="person.file_number.length === 0"><i>No saved file number.</i></p><br>
                    Address: {{person.address_one}}
                        <p v-if="person.address_one.length === 0"><i>No saved address.</i></p><br>
                    <!-- Phone Number: {{person.phone}}
                        <p v-if="person.phone.length === 0"><i>No saved phone number.</i></p><br> -->
                </div>
            </v-col>
        </v-row>

        <v-row class="text-center mt-10">
            <v-col>
                <!-- Edit Button -->
                <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="moveToPage(person.id, '/associations/personEdit')">Edit</v-btn>

                <!-- 'Add Assessment' button -->
                <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="moveToPage(person.id, '/associations/addAssessment')">Add Assessment</v-btn>


                <!-- 'Add Payment' button -->
                <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="moveToPage(person.id, '/associations/addPayment')">Add Payment</v-btn>


                <!-- 'Add Reoccurring' button -->
                <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="moveToPage(person.id, '/associations/addReoccurring')">Add Reoccurring</v-btn>

                <!-- 'Delete All Late Fees' button -->
                <!-- <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="deleteLateFees()">Delete Late Fees</v-btn> -->

                <!-- 'Delete All Late Fees' Button & Confirmation Modal-->
                <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4" v-bind="attrs" v-on="on">
                            Delete All Late Fees</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar color="#4682b4" dark></v-toolbar>
                        <v-card-text>
                            <div class="text-center text-p pa-12">
                                Are you sure you want to delete all late fees (Late Charges and Administrative Late Fees)?
                                <br>
                                This can't be undone.
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn color="error" text @click="this.deleteLateFees">Yes</v-btn>
                            <v-btn color="#4682b4" text @click="dialog = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row class="mt-6">

            <!-- BASIC LEDGER -->

            <v-col class="mb-10">
                <h2 class="text-center pb-2">Print Screen #1  
                    <v-btn elevation="4" icon @click="generatePDF('Basic Ledger')"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
                    <!-- <v-btn elevation="4" icon @click="generateDocx('Basic Ledger')"><v-icon>mdi-file-word</v-icon></v-btn> -->
                </h2>
                <div id="basicTable" ref="content">
                <v-simple-table fixed-header fixed-footer dense class="text-center">
                    <template v-slot:default>
                    
                        <!-- Basic Ledger Table Headers -->
                        <thead>
                            <tr>
                                <th class="text-center">Date</th>
                                <th class="text-center">Type</th>
                                <th class="text-center">Assessment</th>
                                <th class="text-center">Notes</th>
                                <th class="text-center">Payment</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        
                        <!-- Basic Ledger Values -->
                        <!-- While the backend is being loaded into basicLedger[], display this. -->
                        <template v-if="basicLedger.length == 0">
                            <tbody>
                                <tr>
                                    <td>Loading...</td>
                                    <td>Loading...</td>
                                    <td>Loading...</td>
                                    <td>Loading...</td>
                                    <td>Loading...</td>
                                </tr>
                            </tbody>
                        </template>

                        <!-- Otherwise, basicLedger[] has content that can be shown. -->
                        <template v-if="basicLedger">
                            <tbody>
                                <tr v-for="(item, index) in basicLedger" :key="index">
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.type }}</td>
                                    <td v-if="item.assessment > 0"><b>${{ item.assessment.toFixed(2) }}</b></td>
                                    <td v-else>-</td>
                                    <td>{{ item.notes }}</td>
                                    <td v-if="item.payment > 0"><b>${{ item.payment.toFixed(2) }}</b></td>
                                    <td v-else>-</td>

                                    <!-- Delete button and corresponding modal -->
                                    <td>
                                        <delete-modal :id="item.id" :mode="assessOrPay(item.payment)"
                                        v-on:rerender="removeFromTable(item.id)">
                                        </delete-modal>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </template>
                </v-simple-table>
                </div>
            </v-col>
            


            <!-- DETAILED LEDGER -->
            <v-col>
                <h2 class="text-center pb-2">Print Screen #2  
                    <!-- Button to initiate download of detailed ledger as PDF -->
                    <v-btn elevation="4" icon @click="generatePDF('Detailed Ledger')"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
                    <!-- <v-btn elevation="4" icon @click="generateDocx('Detailed Ledger')"><v-icon>mdi-file-word</v-icon></v-btn> -->
                </h2>

                
                <!-- Datepicker to choose how far in the past/future the Detailed Ledger should display -->
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">

                    <template v-slot:activator="{ on }">
                        <v-text-field label="Run Through" prepend-icon="mdi-calendar" 
                            readonly :value="endDate" v-on="on"></v-text-field>
                    </template>

                    <v-date-picker v-model="endDate" no-title @input="fromDateMenu = false" 
                        :min="minDate"></v-date-picker>
                </v-menu>

                <!-- DEBUGGING PURPOSES TO TRY AND FIX RUNTHROUGH BUG... -->
                <!-- <v-checkbox class="justify-contents-right" v-model="runThrough" :label="`Run Through?`"></v-checkbox> -->
                
                <!-- Button to Confirm the Datepicker's selection -->
                <v-btn color="success" class="mr-4" @click="calculateDetailedLedger">Update</v-btn>

                <!-- Button to Reset the Datepicker's selection -->
                <v-btn v-if="endDate != todayDate" color="warning" class="mr-4" 
                    @click="resetDetailedLedger">Reset</v-btn>


                <!-- The actual Detailed Ledger itself -->
                <v-simple-table fixed-header dense class="text-center detailedLedger">
                    <template v-slot:default>
                    
                        <!-- Detailed Ledger headers -->
                        <thead>
                            <tr>
                            <th class="text-center">Date</th>
                            <th class="text-center">Total Assessments</th>
                            <th class="text-center">Total Fees & Costs</th>
                            <th class="text-center">Total Admin Late Fees</th>
                            <th class="text-center">Total Interest</th>
                            <th class="text-center">Payments</th>
                            <th class="text-center">Outstanding Assessments</th>
                            <th class="text-center">Outstanding Fees and Costs</th>
                            <th class="text-center">Outstanding Late Fees</th>
                            <th class="text-center">Outstanding Interest</th>
                            <th class="text-center">Total Due</th>
                            </tr>
                        </thead>

                            
                    
                        <!-- Detailed ledger values -->
                        <tbody>

                            <!-- This is the table's footer that shows the column totals, moved here for
                                now. This will be moved back to the footer when the tables are 
                                shrunken down again. Don't forget to remove the special styling done for
                                this table row while it is up here. -->
                            <tr class="footer">
                                <td>Totals</td>
                                <td class="text-center">${{totalAssessments.toFixed(2)}}</td>
                                <td class="text-center">${{totalFees.toFixed(2)}}</td>
                                <td class="text-center">${{totalAdminLateFees.toFixed(2)}}</td>
                                <td class="text-center">${{totalInterest.toFixed(2)}}</td>
                                <td class="text-center">${{totalPayments.toFixed(2)}}</td>
                                <td class="text-center">${{outstandingPrincipal.toFixed(2)}}</td>
                                <td class="text-center">${{outstandingFees.toFixed(2)}}</td>
                                <td class="text-center">${{outstandingAdminLateFees.toFixed(2)}}</td>
                                <td class="text-center">${{outstandingInterest.toFixed(2)}}</td>
                                <td class="text-center">${{total.toFixed(2)}}</td>
                            </tr>

                            <tr v-for="(item, index) in detailedLedger" :key="index">
                                <td>{{ item.date }}</td>
                                <td v-if="item.assessment> 0"><b>${{ item.assessment.toFixed(2) }}</b></td>
                                <td v-else>$0.00</td>
                                <td v-if="item.fee> 0"><b>${{ item.fee.toFixed(2) }}</b></td>
                                <td v-else>$0.00</td>
                                <td v-if="item.adminLateFee> 0"><b>${{ item.adminLateFee.toFixed(2) }}</b></td>
                                <td v-else>$0.00</td>
                                <td>${{ item.interest.toFixed(2) }}</td>
                                <td v-if="item.payment> 0"><b>${{ item.payment.toFixed(2) }}</b></td>
                                <td v-else>$0.00</td>
                                <td>${{ item.outstandingPrincipal.toFixed(2) }}</td>
                                <td>${{ item.outstandingFees.toFixed(2) }}</td>
                                <td>${{ item.outstandingAdminLateFees.toFixed(2) }}</td>
                                <td>${{ item.outstandingInterest.toFixed(2) }}</td>
                                <td>${{ item.total.toFixed(2) }}</td>


                                <!-- <td>${{ item.adminLateFees.toFixed(2) }}</td>
                                <td v-if="item.payment > 0"><b>${{ item.payment.toFixed(2) }}</b></td>
                                <td v-else>$0.00</td>
                                <td>${{ item.principal.toFixed(2) }}</td>
                                <td>${{ item.outstandingFees.toFixed(2) }}</td>
                                <td>${{ item.total.toFixed(2) }}</td> -->
                            </tr>
                        </tbody>

                        <!-- Detailed Ledger footer for showing totals -->
                        <!-- <tfoot>
                            <tr>
                                <td>Totals</td>
                                <td class="text-center">${{assessments.toFixed(2)}}</td>
                                <td class="text-center">${{fees.toFixed(2)}}</td>
                                <td class="text-center">${{adminLateFees.toFixed(2)}}</td>
                                <td class="text-center">${{payments.toFixed(2)}}</td>
                                <td class="text-center">${{principal.toFixed(2)}}</td>
                                <td class="text-center">${{interest.toFixed(2)}}</td>
                                <td class="text-center">${{outstandingFees.toFixed(2)}}</td>
                                <td class="text-center">${{total.toFixed(2)}}</td>
                            </tr>
                        </tfoot> -->
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import PaymentService from '@/services/PaymentService'
    import PeopleService from '@/services/PeopleService'
    import AssociationsService from '@/services/AssociationsService'
    import DeleteModal from '../../components/DeleteModal.vue'
    import jsPDF from 'jspdf'

    import { Document, Paragraph, Packer, Table, TableRow, TableCell, WidthType } from "docx";
    import { saveAs } from 'file-saver'

    export default {
        name: 'peopleDetails',
        data: () => ({
            dialog: false,
            person: null,
            association: null,
            runThrough: false,
            
            assessmentTypes: [],
            paymentTypes: [],
            basicLedger: [],
            detailedLedger: [],
            
            totalAssessments: 0,
            totalFees: 0,
            totalAdminLateFees: 0,
            totalInterest: 0,

            totalPayments: 0,

            outstandingPrincipal: 0,
            outstandingFees: 0,
            outstandingAdminLateFees: 0,
            outstandingInterest: 0,

            total: 0,
            
            fromDateMenu: false,
            endDate: null,
            minDate: null,
            todayDate: null,
        }),

        // Vue mounted function (async) to hit API end-points
        async mounted () {
            
            // personID is the id of the person, fetched from the URL
            const personID = this.$store.state.route.params.person_id

            // person is the entry from the backend that has the same id as the above personID variable
            this.person = await PeopleService.getPersonByID({person_id: personID})
            this.person = this.person.data
            
            // Wrapping all of the async calls in a Promise.all so they can be fetched simultaneously.
            const [associationByID, assessTypes, payTypes, paymentByID, assessmentByID] = await Promise.all([
                AssociationsService.getAssociationByID({association_id: this.person.association_id}),
                PaymentService.getAllAssessmentTypes(),
                PaymentService.getAllPaymentTypes(),
                PaymentService.getPaymentsByPersonID({person_id: personID}),
                PaymentService.getAssessmentsByPersonID({person_id: personID})
            ])


            // Save associationByID into association to access its data.
            this.association = associationByID.data
            
            // Save all the types of Assessments into assessmentTypes[].
            assessTypes.data.forEach(element => {
                this.assessmentTypes.push([element.id, element.name])
            })

            // Save all the types of Payments into paymentTypes[].
            payTypes.data.forEach(element => {
                this.paymentTypes.push([element.id, element.name])
            })


            // Put all the payments this person has made into basicLedger[]
            paymentByID.data.forEach(element => {

                let notes = "-";
                if (String(element.note).includes("Part of a reocurring") || element.note == "" || element.note == null) { notes = "-" }
                else { notes = String(element.note) }

                this.basicLedger.push({
                    id: element.id,
                    date: element.date,
                    type: this.convertToPaymentType(element.payment_type_id),
                    assessment: 0,
                    notes: notes,
                    payment: element.amount
                })
            })


            // Put all the assessments this person has made into basicLedger[]
            assessmentByID.data.forEach(element => {
                let notes = "-";
                if (String(element.note).includes("Part of a reocurring") || element.note == "" || element.note == null) { notes = "-" }
                else { notes = String(element.note) }

                this.basicLedger.push({
                    id: element.id,
                    date: element.date,
                    type: this.convertToAssessmentType(element.assessment_type_id),
                    assessment: element.amount,
                    // notes: element.note,
                    notes: notes,
                    payment: 0
                })
            })
            
            // Sort basicLedger[] based off of the date fields.
            this.basicLedger.sort((a, b) => {
                return (a.date > b.date) ? 1 : -1
            })

            // Set the minimum date for the DatePicker
            const [startYear, startMonth, startDay] = this.basicLedger[0].date.split("-")
            this.minDate = `${startYear}-${startMonth}-${startDay}`

            // Get today's date.
            const today = new Date()
            this.todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, "0")}-${(today.getDate()).toString().padStart(2, "0")}`
            this.endDate = this.todayDate

            this.calculateDetailedLedger()
        },

        methods: {

            deleteLateFees() {
                PeopleService.deleteAllLateFees({ person_id: this.person.id });
                this.dialog = !this.dialog;
                this.$router.go();
            },

            generateDocx(ledgerType) {
                // If the basic ledger docx button is selected
                if (ledgerType == 'Basic Ledger') {


                    // Loop through basic ledger and set values to headers selected above
                    let data = [];
                    let personPayment = 0;
                    this.basicLedger.forEach(el => {
                        // Since most are not payments, a simple '-' looks cleaner than all '0'
                        if (el.payment == 0) {
                            personPayment = "-"
                        } else {
                            personPayment = String((el.payment).toFixed(2))
                        }
                        let obj = {
                            Date: el.date,
                            Type: el.type,
                            Assessment: String((el.assessment).toFixed(2)),
                            Payment: personPayment
                        }
                        data.push(obj);  // Push created object onto initialized data array
                    });

                    // Create header row for table
                    let headerRow = new TableRow({
                        children: [
                            new TableCell({
                                width: {size: 4505, type: WidthType.DXA},
                                children: [new Paragraph("Date")],
                            }),
                            new TableCell({
                                width: {size: 4505, type: WidthType.DXA},
                                children: [new Paragraph("Type")],
                            }),
                            new TableCell({
                                width: {size: 4505, type: WidthType.DXA},
                                children: [new Paragraph("Assessment")],
                            }),
                            new TableCell({
                                width: {size: 4505, type: WidthType.DXA},
                                children: [new Paragraph("Payment")],
                            }),
                        ],
                        tableHeader: true,
                    })

                    const table2 = new Table({
                        columnWidths: [2000, 2000, 2000, 2000],
                        rows: [headerRow],
                    });
                    const doc = new Document({
                        sections: [{children: [new Paragraph({ text: "UNDER DEVELOPMENT" }), table2]},],
                    });
                    this.saveDocxToFile(doc, ledgerType+'.docx')
                } 
                else { console.log("Currently working on the Basic Ledger DOCX.  Check back later for the Detailed Ledger DOCX option."); }
            },

            // Helper Method for generateDocx() to take generated document and save as Docx file
            saveDocxToFile(doc, fileName) {
                // Create a mime type that will associate the new file with Microsoft Word
                const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                // Create a Blob object from Packer containing the Document instance and the mimeType
                Packer.toBlob(doc).then((blob) => {
                    const docblob = blob.slice(0, blob.size, mimeType)
                    // Save the file using saveAs from the file-saver package
                    saveAs(docblob, fileName)
                })
            },

            // Generates pdf version of ledger
            generatePDF(ledgerType) {

                // If the Basic ledger PDF button is selected...
                if (ledgerType == 'Basic Ledger') {
                    const pdf = new jsPDF({
                        orientation: "landscape"
                    });

                    let margins = {
                        top: 10,
                        bottom: 10,
                        left: 10,
                        width: 550
                    };

                    // Set the headers, which are also used as the keys within the headerConfig mapping below
                    let header = ["Date","Type","Assessment","Notes","Payment"];
                    let headerConfig = header.map(key=>({ 
                        'name': key,
                        'prompt': key,
                        'width':75,
                        'align':'center',
                        'padding':0})
                    );

                    // List headers and assign as keys via map
                    let totalsTableHeaders = ["Date", "Total  Assessments Charged","Total Fees Costs Charged","Total Outstanding Admin Late Fees Charged","Total Interest Charged", "Payments", 
                                "Outstanding Principal", "Outstanding Fees and Costs", "Outstanding Admin Late Fees", "Outstanding Interest", "Outstanding Total"];
                    let totalsHeaderConfig = totalsTableHeaders.map(key=>({ 
                        'name': key,
                        'prompt': key,
                        'width':34,
                        'align':'center',
                        'padding':0})
                    );


                    let tableConfig = ({ 
                        'fontSize': 8,
                        'margins': margins
                    });

                    // TOTALS ROW FROM DETAILED LEDGER
                    let totalsRow = [];
                    let totalRowObj = {
                            Date: "Totals",
                            'Total  Assessments Charged': String((this.totalAssessments).toFixed(2)),
                            'Total Fees Costs Charged': String((this.totalFees).toFixed(2)),
                            'Total Outstanding Admin Late Fees Charged': String((this.totalAdminLateFees).toFixed(2)),
                            'Total Interest Charged': String((this.totalInterest).toFixed(2)),
                            Payments: String((this.totalPayments).toFixed(2)),
                            'Outstanding Principal': String((this.outstandingPrincipal).toFixed(2)),
                            'Outstanding Fees and Costs': String((this.outstandingFees).toFixed(2)),
                            'Outstanding Admin Late Fees': String((this.outstandingAdminLateFees).toFixed(2)),
                            'Outstanding Interest': String((this.outstandingInterest).toFixed(2)),
                            'Outstanding Total': String((this.total).toFixed(2)),
                    }
                    totalsRow.push(totalRowObj);
                    

                    // Loop through basic ledger and set values to headers selected above
                    let data = [];
                    let personPayment = 0;
                    let notes = "-";

                    this.basicLedger.forEach(el => {
                        // console.log(el)
                        // Since most are not payments, a simple '-' looks cleaner than all '0'
                        if (el.payment == 0) { personPayment = "-" } 
                        else { personPayment = String((el.payment).toFixed(2)) }

                        if (String(el.notes).includes("Part of a reocurring") || el.notes == "" || el.notes == null) { notes = "-" }
                        else { notes = String(el.notes) }

                        let obj = {
                            Date: el.date,
                            Type: el.type,
                            Assessment: String((el.assessment).toFixed(2)),
                            Notes: notes,
                            Payment: personPayment
                        }
                        data.push(obj);  // Push created object onto initialized data array
                    });

                    // "Compile" table and save via provided/passed-through name
                    pdf.setFontSize(12);
                    pdf.text("Name: " + this.person.first_name + " " + this.person.last_name, 10, 6);

                    if (!this.person.address_one) { pdf.text("Address: No address currently on file", 10, 12); }
                    else { pdf.text("Address: " + this.person.address_one, 10, 12); }

                    pdf.text("Association: " + this.association.name, 10, 18);
                    pdf.text("Interest Rate: " + String(this.association.interest), 10, 24);

                    if (!this.person.file_number) { pdf.text("File Number: No file number available", 10, 30); }
                    else { pdf.text("File Number: " + String(this.person.file_number), 10, 30); }
                    
                    pdf.text("Date: " + String(this.todayDate), 10, 36);
                    pdf.table(10, 40, totalsRow, totalsHeaderConfig, tableConfig);
                    pdf.table(10, 75, data, headerConfig, tableConfig);
                    pdf.save(ledgerType + "_" + this.person.first_name + "_" + this.person.last_name);
                }

                // If the Detailed ledger PDF button is selected...
                else {

                    // Edit the config and set to landscape since there are so many headers
                    const pdf = new jsPDF({
                        orientation: "landscape"
                    });

                    // Set page margins to add to tableConfig later (prevents unwanted printing at very top of page)
                    let margins = {
                        top: 10,
                        bottom: 10,
                        left: 10,
                        width: 550
                    };

                    // List headers and assign as keys via map
                    let header = ["Date", "Total  Assessments Charged","Total Fees Costs Charged","Total Outstanding Admin Late Fees Charged","Total Interest Charged", "Payments", 
                                "Outstanding Principal", "Outstanding Fees and Costs", "Outstanding Admin Late Fees", "Outstanding Interest", "Outstanding Total"];
                    let headerConfig = header.map(key=>({ 
                        'name': key,
                        'prompt': key,
                        'width':34,
                        'align':'center',
                        'padding':0})
                    );

                    // Set table configuration to pass into 'pdf.table' later
                    let tableConfig = ({ 
                        'fontSize': 8,
                        'margins': margins
                    })

                    // Loop through detailed ledger and set values to headers selected above
                    //   Also holds the totals row at the top (i.e. - the beginning of the array)
                    let data = [];

                    let totalRowObj = {
                            Date: "Totals",
                            'Total  Assessments Charged': String((this.totalAssessments).toFixed(2)),
                            'Total Fees Costs Charged': String((this.totalFees).toFixed(2)),
                            'Total Outstanding Admin Late Fees Charged': String((this.totalAdminLateFees).toFixed(2)),
                            'Total Interest Charged': String((this.totalInterest).toFixed(2)),
                            Payments: String((this.totalPayments).toFixed(2)),
                            'Outstanding Principal': String((this.outstandingPrincipal).toFixed(2)),
                            'Outstanding Fees and Costs': String((this.outstandingFees).toFixed(2)),
                            'Outstanding Admin Late Fees': String((this.outstandingAdminLateFees).toFixed(2)),
                            'Outstanding Interest': String((this.outstandingInterest).toFixed(2)),
                            'Outstanding Total': String((this.total).toFixed(2)),
                    }
                    data.push(totalRowObj);

                    this.detailedLedger.forEach(el => {
                        let obj = {
                            Date: el.date,
                            'Total  Assessments Charged': String((el.assessment).toFixed(2)),
                            'Total Fees Costs Charged': String((el.fee).toFixed(2)),
                            'Total Outstanding Admin Late Fees Charged': String((el.adminLateFee).toFixed(2)),
                            'Total Interest Charged': String((el.interest).toFixed(2)),
                            Payments: String((el.payment).toFixed(2)),
                            'Outstanding Principal': String((el.outstandingPrincipal).toFixed(2)),
                            'Outstanding Fees and Costs': String((el.outstandingFees).toFixed(2)),
                            'Outstanding Admin Late Fees': String((el.outstandingAdminLateFees).toFixed(2)),
                            'Outstanding Interest': String((el.outstandingInterest).toFixed(2)),
                            'Outstanding Total': String((el.total).toFixed(2)),

                        }
                        data.push(obj); // Push created object onto initialized data array
                    });

                    // "Compile" table and save via provided/passed-through name
                    pdf.setFontSize(12);
                    pdf.text("Name: " + this.person.first_name + " " + this.person.last_name, 10, 6);
                    if (!this.person.address_one) { pdf.text("Address: No address currently on file", 10, 12); }
                    else { pdf.text("Address: " + this.person.address_one, 10, 12); }
                    pdf.text("Association: " + this.association.name, 10, 18);
                    pdf.text("Interest Rate: " + String(this.association.interest), 10, 24);
                    pdf.text("File Number: " + String(this.person.file_number), 10, 30);
                    pdf.text("Date: " + String(this.todayDate), 10, 36);
                    pdf.table(10, 40, data, headerConfig, tableConfig);
                    pdf.save(ledgerType);
                }

            },

            // This method resets everything involving the Detailed Ledger and recalculates it to the
            //     current endDate.
            calculateDetailedLedger() {
              // JRL need to reset our data variables to 0 since we are rebuilding the ledger
              this.outstandingPrincipal = 0
              this.totalAssessments = 0
              this.totalFees = 0
              this.totalAdminLateFees = 0
              this.totalInterest = 0
              this.totalPayments = 0
              this.outstandingPrincipal = 0
              this.outstandingFees = 0
              this.outstandingAdminLateFees = 0
              this.outstandingInterest = 0
              this.total = 0


                // tempLedger is a shallow copy of basicLedger used for calculating the Detailed Ledger.
                let tempLedger = [...this.basicLedger]


                // start is the date of the first charge to the person.
                // It will also be used as a counter to keep track of the date in the following loop.
                const [startYear, startMonth, startDay] = tempLedger[0].date.split("-")
                let start = new Date(startYear, startMonth - 1, startDay)
                
                // end is the proper Date representation of endDate.
                // It is used as the sentinel for breaking out of the following loop.
                const [endYear, endMonth, endDay] = this.endDate.split("-")
                let end = new Date(endYear, endMonth - 1, endDay)
    

                // Reset detailedLedger[].
                this.detailedLedger = []

                // Run until we reach the end date.
                while (start <= end) {

                    // date needs to be in YYYY-MM-DD format.
                    // toISOString() converts start into the ISO standard YYYY-MM-DDTHH:mm:ss.sssZ, where
                    //     'T' is a character that always appears in the string to indicate the subsequent
                    //     characters as the hours, minutes, seconds, and milliseconds respectively.
                    // So, December 5th 2021 at 12:42pm would be written as 2021-12-05T12:42:00.000Z
                    // Splitting the string on the 'T' and indexing the first item of the resulting list
                    //     gets the final desired YYYY-MM-DD sans timestamp.
                    const date = start.toISOString().split("T")[0]

                    // Create a default object entry{} that will be saved into detailedLedger[].
                    let entry = {
                        date: date,
                        assessment: 0,
                        fee: 0,
                        adminLateFee: 0,
                        interest: 0,
                        payment: 0,
                        outstandingPrincipal: 0,
                        outstandingFees: 0,
                        outstandingAdminLateFees: 0,
                        outstandingInterest: 0,
                        total: this.total
                    }


                    // Calculate interest first.
                    entry.interest = ((this.association.interest / 100) / 365) * (this.outstandingPrincipal)
                    this.outstandingInterest += entry.interest

                    // If there are still entries in tempLedger, and the most recent entry is the same
                    //     day as date, overwrite some of the fields in entry{} with the saved values.                
                    if (tempLedger.length > 0 && date == tempLedger[0].date) {

                        // Use a while in case multiple charges occur on the same day.
                        while (date == tempLedger[0].date) {

                            // The field in entry{} that needs to be changed will depend on what
                            //     assessment/payment type the entry is.
                            // assessValue is used as an easier way to access tempLedger[0].assessment 
                            //     multiple times.
                            // payValue is used as an easier way to access tempLedger[0].payment
                            //     multiple times.
                            const assessValue = tempLedger[0].assessment
                            let payValue = tempLedger[0].payment
                            switch (tempLedger[0].type) {
                                
                                // The Assessments are assessment_types 1, 5, 6, 7, 10
                                case 'Monthly':
                                case 'quarterly':
                                case 'Semi-annual':
                                case 'Annual':
                                case 'Special Assessments':
                                    this.outstandingPrincipal += assessValue
                                    entry.assessment += assessValue
                                    break

                                
                                // The Fees are assessment_type 8, 9, 11, 12
                                case 'Collection Costs':
                                case 'Lawn Maintenance':
                                case "Attorney's Fees":
                                case 'court costs':
                                    this.outstandingFees += assessValue
                                    entry.fee += assessValue
                                    break


                                // The Admin Late Fees are assessment_type 3, 13
                                case 'Late Charges':
                                case 'Administrative Late Fees':
                                    this.outstandingAdminLateFees += assessValue
                                    entry.adminLateFee += assessValue
                                    break


                                // The only Payment is payment_type 1
                                case 'monthly payment':
                                    entry.payment = payValue

                                    // First, subtract from the Outstanding Interest column.
                                    if (payValue >= this.outstandingInterest) {
                                        payValue -= this.outstandingInterest
                                        this.outstandingInterest = 0
                                    } else {
                                        this.outstandingInterest -= payValue
                                        payValue = 0
                                    }

                                    // Next, subtract from the Administrative Late Fees column
                                    if (payValue >= this.outstandingAdminLateFees) {
                                        payValue -= this.outstandingAdminLateFees
                                        this.outstandingAdminLateFees = 0
                                    } else {
                                        this.outstandingAdminLateFees -= payValue
                                        payValue = 0
                                    }

                                    // Next, subtract from the Outstanding Fees column.
                                    if (payValue >= this.outstandingFees) {
                                        payValue -= this.outstandingFees
                                        this.outstandingFees = 0
                                    } else {
                                        this.outstandingFees -= payValue
                                        payValue = 0
                                    }

                                    // Finally, subtract from the Principal column
                                    if (payValue > 0) {
                                        this.outstandingPrincipal -= payValue
                                    }

                                    break


                                // In the case a new assessment or payment type is created in the future
                                default:
                                    alert(`The item ${tempLedger[0].type} is not recognised, and will not be calculated.`)
                                    break
                                
                            }
                            tempLedger.splice(0, 1)
                            if (tempLedger.length == 0) break
                        }
                    }

                    // These fields are tallied totals that need to be added back into entry{}
                    entry.outstandingPrincipal = this.outstandingPrincipal
                    entry.outstandingFees = this.outstandingFees
                    entry.outstandingAdminLateFees = this.outstandingAdminLateFees
                    entry.outstandingInterest = this.outstandingInterest
                    entry.total = entry.outstandingPrincipal + entry.outstandingFees + entry.outstandingAdminLateFees + entry.outstandingInterest
                    this.total = entry.total

                    // Add entry{} with any updates to detailedLedger[].
                    this.detailedLedger.push(entry)

                    // Advance start to the next day.
                    start = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 1)
                }

                // Update the values that will be stored in the Totals Row of the DETAILED LEDGER
                this.updateTotals()
            },

            // This method resets the Detailed Ledger by updating endDate to todayDate and recalculating
            //     it.
            resetDetailedLedger() {
                this.endDate = this.todayDate

                this.calculateDetailedLedger()
            },


            // When an entry in the backend database is deleted, this removes the item from basicLedger[].
            removeFromTable(itemID) {

                // First, find the index of the object in basicLedger[] that has the same id as itemID
                const result = this.basicLedger.findIndex(element => element.id == itemID)

                // Then, remove it from basicLedger[].
                this.basicLedger.splice(result, 1)
            },


            // Used in the BASIC LEDGER table to show what the payment/assessment type is rather than just
            //     a number.
            convertToPaymentType(id) {

                 // Loop over every entry in paymentTypes
                for (const element of this.paymentTypes) {
                    
                    // If the saved id in paymentTypes is equivalent to id, return the paymentType.
                    if (element[0] == id) return element[1]
                }
                
                // In case somehow id is not in paymentTypes, use this message.
                return 'No payment_type matching this ID found.'
            },
            convertToAssessmentType(id) {

                // Loop over every entry in assessmentTypes
                for (const element of this.assessmentTypes) {
                    
                    // If the saved id in assessmentTypes is equivalent to id, return the assessmentType.
                    if (element[0] == id) {
                        return element[1]
                    }
                }
                
                // In case somehow id is not in assessmentTypes, use this message.
                return 'No assessment_type matching this ID found.'
            },


            assessOrPay(payment_amt) {

                let mode = ''

                // If payment_amt is 0, that means the item that needs to be deleted is an assessment.
                if (payment_amt == 0) {
                    mode = 'assessment'
                }

                // Else the item to be deleted is a payment.
                else {
                    mode = 'payment'
                }

                return mode
            },


            updateTotals() {
                let finalRow = this.detailedLedger[this.detailedLedger.length - 1]

                // Some of the values don't have to be tallied, because they are already saved in 
                //     this.detailedLedger[].
                // However, the ones that aren't need to be reset so we can loop through and recount them.
                this.totalAssessments = 0
                this.totalFees = 0
                this.totalAdminLateFees = 0
                this.totalInterest = 0
                this.totalPayments = 0
                this.outstandingPrincipal = finalRow.outstandingPrincipal
                this.outstandingFees = finalRow.outstandingFees
                this.outstandingAdminLateFees = finalRow.outstandingAdminLateFees
                this.outstandingInterest = finalRow.outstandingInterest
                this.total = finalRow.total

                this.detailedLedger.forEach(element => {
                    this.totalAssessments += element.assessment
                    this.totalFees += element.fee
                    this.totalAdminLateFees += element.adminLateFee
                    this.totalInterest += element.interest
                    this.totalPayments += element.payment
                })
            },

            moveToPage(id, route) {
                if (route == '/associations/peopleDetails') {
                    this.$router.push({ path: `${route}/${id}`})
                } else if (route == '/associations/personEdit') {
                    this.$router.push({ path: `${route}/association_id=${this.associationID}&person_id=${id}`})
                }
                else{
                    this.$store.dispatch('setPersonID', id)
                    this.$router.push({ path: route})
                }
            },
        },


        components: {
            // Importing the DeleteModal component allows the use of it as <delete-modal>
            DeleteModal
        }
    }
</script>

<style>
    tbody tr:nth-of-type(odd) {
        background: rgba(0, 0, 0, .05);
    }

    tfoot {
        position: sticky;
        bottom: 0;
        background: lightgray;
        font-weight: bold;
    }

    /* Class for the table footer when it is at the top of the table. Should be removed when the table
        height is set and the footer is moved back down to the bottom. */
    .footer {
        background: lightgray;
        font-weight: bold;
    }

    body {
        background-color: var(--v-accent-lighten2) !important;
        /* padding-bottom: 75px; */
    }
</style>

<style scoped>
    p {
        display: inline;
    }
</style>