<template>
    <v-dialog transition="dialog-bottom-transition" max-width="600" persistent>

        <!-- Button to Activate Modal -->
        <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="2" :class="button_classes" outlined color="error" v-bind="attrs" v-on="on">
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </template>

        <!-- Confirmation Modal -->
        <template v-slot:default="dialog">
            <v-card>
                <v-toolbar color="#4682b4" dark></v-toolbar>
                <v-card-text>
                    <div class="text-center text-p pa-12">
                        Are you sure you want to delete this record?
                        <br>
                        This can't be undone.
                    </div>
                </v-card-text>

                <!-- Buttons within confirmation modal -->
                <v-card-actions class="justify-center">
                    <v-btn text color="error" @click="dialog.value = deleteFrom()">Yes</v-btn>
                    <v-btn text color="#4682b4" @click="dialog.value = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </template>


    </v-dialog>
</template>

<script>
    import PaymentService from '@/services/PaymentService'
    import AssociationsService from '@/services/AssociationsService'
    export default ({

        props: ['mode', 'id', 'button_classes'],

        methods: {
            deleteFrom () {

                // JSON{} is the object that is passed into every delete method.
                // It is used to simplify the copy-pasting.
                const JSON = { id: this.id }

                // mode is the way to select which database the entry should be deleted from.
                switch (this.mode) {
                    case 'assessment':
                        PaymentService.deleteAssessment(JSON)
                        break
                    case 'payment':
                        PaymentService.deletePayment(JSON)
                        break
                    case 'association':
                        AssociationsService.deleteAssociation(JSON)
                        break;
                    default:
                        console.log("Deletion mode not recognized.")
                }

                // Emit rerender to update the places where this component is used.
                this.$emit('rerender')
                
                // Return false so the modal will close after execution.
                return false
            }
        }
    })
</script>
